import React, {Fragment} from 'react';

import Header from '../components/Header'
import Slider from '../components/Slider/home-one'
import About from '../components/About/home-one'
//import Features from '../components/Features'
import PageAbout from "../components/About/page";
import Services from '../components/Services'
import Team from "../components/Team/home-one";
import Blog from "../components/Blog";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
//import Testimonials from "../components/Testimonials/home-one";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from '../assets/img/about2.jpg'

const HomeOne = () => {
    return (
        <Fragment>
            <Header/>
            <Slider/>
            <About/>
            <PageAbout
                title={'FOR EMPLOYERS'}
                heading="DISCOVER THE BEST TALENT"
                thumb={ServiceThumb}
                content="Our industry development experts will understand your business needs and can connect you with top talent to support your project and organizational needs on a temporary or long-term basis. Our professionals are available for on-site or remote work all over the country."
            />
            <Services classes="sm-top-wt"/>
            {/*<Features classes="sp-top"/>
            <Services classes="sm-top-wt"/>*/}
            {/*<Testimonials/>
            <Team/>
            <Blog/>
            <BrandLogo/>
            <Funfact/>
            <CallToAction/>*/}
            <Footer/>
            <MobileMenu/>
            <LoginRegister/>
        </Fragment>
    );
};

export default HomeOne;