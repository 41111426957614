import React, { useState } from 'react';
import Thumbnail from "../../UI/Thumbnail";
import List from "../../UI/List";
import LI from "../../UI/List/Item";
import Text from "../../UI/Text";
import IconBox from "../../IconBox";

import aboutData from '../../../api/About/home-three'
import aboutData2 from '../../../api/About/home-three-2'
import HowWeWorksData from "../../../api/About/how-we-works";

const About = () => {

    const [about] = useState(aboutData);
    const [about2] = useState(aboutData2);

    return (
        <div className="about-area-wrapper sm-top">
            <div className="container">
                <div className="row align-items-lg-center">
                    <div className="col-md-6 col-lg-5">
                        <Thumbnail classes="about-thumb" imgSrc={require('../../../assets/img' + about.thumb)} />
                    </div>

                    <div className="col-md-6 col-lg-7">
                        <div className="service-details-info">
                            <div className="about-service mt-50 mt-sm-35">
                                <h4>WHO IS DIVERSITY GEEKS?</h4>
                                {
                                    <div dangerouslySetInnerHTML={{ __html: "FINDING THE DIVERSE TALENT TO DRIVE YOUR BUSINESS FORWARD." }} />
                                }
                                <List classes="service-feature mt-30">
                                    {
                                        about.features.map((feature, indx) => (
                                            <LI key={indx}>{feature}</LI>
                                        ))
                                    }
                                </List>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container">
                <div className="row align-items-lg-center">
                    <div className="col-md-6 col-lg-7">
                        <div className="service-details-info">
                            <div className="about-service mt-50 mt-sm-35">
                                <h4>WHO IS DIVERSITY GEEKS?</h4>
                                {
                                    <div dangerouslySetInnerHTML={{ __html: "FINDING THE DIVERSE TALENT TO DRIVE YOUR BUSINESS FORWARD." }} />
                                }
                                <List classes="service-feature mt-30">
                                    {
                                        about2.features.map((feature, indx) => (
                                            <LI key={indx}>{feature}</LI>
                                        ))
                                    }
                                </List>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-5 order-0 order-md-1">
                        <Thumbnail classes="about-thumb" imgSrc={require('../../../assets/img' + about2.thumb)} />
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row align-items-lg-center">
                    <div className="col-md-6 col-lg-7">
                        <div className="service-details-info">
                            <div className="how-we-works-wrap mt-50 mt-sm-35">
                                <h4>Our Solutions</h4>
                                <Text styles={{ marginBottom: 20 }}>
                                We help businesses build bring diverse talent to their organization. DIVERSITY GEEKS. It’s more than a solution; it’s who we are.
                                </Text>

                                <div className="how-we-works-content service-details-page">
                                    <div className="row">
                                        {
                                            HowWeWorksData.map(iconbox => (
                                                <IconBox
                                                    key={iconbox.id}
                                                    icon={iconbox.icon}
                                                    title={iconbox.title}
                                                    text={iconbox.text}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-5 order-0 order-md-1">
                        <Thumbnail classes="about-thumb" imgSrc={require('../../../assets/img' + about2.thumbHow)} />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default About;