import React, {useState} from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import {Link} from "react-router-dom";
import Logo from '../../assets/img/diversitygeeks.png' //logo-dark
import contactData from '../../api/Contact/contact-one'
import aboutData from '../../api/About/home-one'
function Footer() {

    const [contact] = useState(contactData);
    const [aboutUs] = useState(aboutData);
    return (
        <footer className="footer-area sp-bottom" style={{backgroundColor:"black",color:"white"}}>
            <div className="container">
                <div className="row mtn-40">
                    <div className="col-lg-4 order-4 order-lg-0">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                                    <img src={Logo} alt="Logo"/>
                                </Link>

                                <Text>
                                    {aboutUs.details}
                                </Text>

                                <Text classes="copyright-txt">
                                    &copy; {new Date().getFullYear()} {contact.name} Ltd. All Rights Reserved.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Information">
                            <List classes="widget-list">
                                <LI><Link to={`${process.env.PUBLIC_URL + "/about"}`}>Our company</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/contact"}`}>Contact us</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/services"}`}>Our services</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/jobs"}`}>Our Jobs</Link></LI>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Social Links">
                            <List classes="widget-list">
                                <LI><Link to="https://facebook.com/diversitygeeks/" target={'_blank'}>Facebook</Link></LI>
                                <LI><Link to="https://twitter.com/diversitygeeks/" target={'_blank'}>Twitter</Link></LI>
                                <LI><Link to="https://linkedin.com/diversity-geeks/" target={'_blank'}>LinkedIn</Link></LI>
                                <LI><Link to="https://www.instagram.com/diversitygeeks" target={'_blank'}>Instagram</Link></LI>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-3">
                        <Widget title="Contact Us">
                            <address>
                                {contact.address} <br/>
                                {contact.web} <br/>
                                {contact.phoneTollFree}
                            </address>
                        </Widget>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;