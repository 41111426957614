import Amplify, { Auth } from 'aws-amplify';
import authConfig from './auth.config';
import { userGroups } from './constants';

export const configureAuth = () => {
    Amplify.configure(authConfig);
}

export const getCurrentAuthenticatedUser = () => {
    return Auth.currentAuthenticatedUser();
}

export const getCurrentUserInfo = () => {
    return Auth.currentUserInfo();
}

export const signIn = () => {
    if (!window.location.pathname.startsWith("/auth/")) {
        console.log('Not signed in');
        setLoginRedirectURL();
        return Auth.federatedSignIn();
    }

    return new Promise(() => { return false; }, () => { return false; });
}

export const signOut = () => {
    return Auth.signOut();
}

export const currentSession = () => {
    return Auth.currentSession();
}

export const getCurrentUserGroups = async () => {
    let session = await currentSession();
    let userGroups = session.getAccessToken().decodePayload()['cognito:groups'];

    return userGroups ?? [];
}

export const isCurrentUserAdmin = async () => {
    return await isCurrentUserInGroup(userGroups.Admin);
}

export const isCurrentUserInGroup = async (group) => {
    let currentUserGroups = await getCurrentUserGroups();

    return currentUserGroups.indexOf(group) > -1;
}

export const setLoginRedirectURL = () => {    
    sessionStorage.setItem("LoginRedirectURL", window.location.pathname);
}

export const getLoginRedirectURL = () => {
    return sessionStorage.getItem("LoginRedirectURL") ?? "/";
}