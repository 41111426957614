const authConfig = {
    Auth: {
      region: 'us-east-1',
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      cookieStorage: {
        path: '/',
        expires: 30,
        domain: window.location.hostname,
        secure: process.env.REACT_APP_USER_POOL_COOKIE_SECURE === 'true',
      },
      oauth: {
        domain: process.env.REACT_APP_USER_POOL_AUTH_DOMAIN,
        scope: process.env.REACT_APP_USER_POOL_SCOPES.split(','),
        redirectSignIn: `${window.location.origin}${process.env.REACT_APP_USER_POOL_REDIRECT_PATH_SIGN_IN}`,
        redirectSignOut: `${window.location.origin}${process.env.REACT_APP_USER_POOL_REDIRECT_PATH_SIGN_OUT}`,
        responseType: 'code'
      }
    }
  };
  
  export default authConfig;