import axios from "axios";
import { currentSession } from "../common/auth";
import { Environment } from '../environments/Environment';

export const configureAxios = () => {
    axios.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8';
    axios.interceptors.request.use(async (request) => await requestHandler(request));
    axios.interceptors.response.use(
        response => successHandler(response),
        error => errorHandler(error)
    );
}

const requestHandler = async (request) => {
    if (isHandlerEnabled(request)) {
        request.headers['UI-Version'] = Environment.appVersion;
        try {
            let userSession = await currentSession();
            let idToken = await userSession.getIdToken().getJwtToken();
            request.headers['Authorization'] = 'Bearer ' + idToken;
        }
        catch {
            console.log("No Active User Session");
            window.location.reload(false);
        }
    }
    return request;
}

const isHandlerEnabled = (config = {}) => {
    return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ?
        false : true;
}

const errorHandler = (error) => {
    if (isHandlerEnabled(error.config)) {
        // Handle errors
        if (error.response) {
            if (error.response.status === 401) {
                if (error.response?.data?.lastIndexOf("Version Mismatch") > -1) {
                    if (window.VersionMismatch === null || window.VersionMismatch === undefined) {
                        let response = window.confirm("We have got new updates!\nPlease close any other open tabs and Click on 'OK' to load latest updates.");
                        if (response) {
                            window.VersionMismatch = true;
                            window.location.reload(false);
                        }
                        else {
                            window.VersionMismatch = null;
                        }
                    }
                }
                else {
                    console.log("User Un-authroized");
                    if (error.response?.data?.toLowerCase().lastIndexOf("vertical") > -1) {
                        alert(error.response?.data);
                    }
                    else {
                        window.location.reload(false);
                    }
                }
            }
            else if (error.response.status === 403) {
                window.location.href = "/";
            }
        }
    }
    return Promise.reject({ ...error });
}

const successHandler = (response) => {
    if (isHandlerEnabled(response.config)) {
        // Handle responses
    }
    return response;
}

export const saveFile = async (file) => {
    await axios({ url: file.downloadUrl, method: 'GET', responseType: 'blob', handlerEnabled: false })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file.name}`);
            document.body.appendChild(link);
            link.click();
        });
}