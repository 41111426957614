import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import PricingTable from "../components/PricingTable";
import Testimonial from "../components/Testimonials/home-two";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from '../assets/img/about2.jpg'

const PageService = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/services.jpg')}
                title=""
                content=""
            />
            <PageAbout
                title={'FOR EMPLOYERS'}
                heading="DISCOVER THE BEST TALENT"
                thumb={ServiceThumb}
                content="Our industry development experts will understand your business needs and can connect you with top talent to support your project and organizational needs on a temporary or long-term basis. Our professionals are available for on-site or remote work all over the country."
            />
            <Services classes="sm-top"/>
            {/*<PricingTable/>
            <Testimonial/>
            <BrandLogo/>
            <Funfact classes="sp-top"/>*/}
            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageService;